import React from 'react'
import classNames from 'classnames'
import Layout from 'layouts/en'
import {
  Banner,
  SummaryTitle,
  TabSwitcher,
  // MoreResources,
  Button,
  // ScanCodeConsultButton,
  TestimonyCard,
  PageBottomCard,
} from 'components/index'
import CardGroup from 'components/CdpCom/CardGroup'
import Seo from 'components/EN/Seo'
import * as styles from './index.module.less'
import CardsCol from 'components/CdpCom/CardsCol'
import ScrollLogoWall from 'components/EN/ScrollLogoWall'
import classnames from 'classnames'
import AnalysisCard from 'components/Analysis/AnalysisCard'

import part1_1 from 'assets/images/product/cdp/part1_1.png'
import part1_2 from 'assets/images/product/cdp/part1_2.png'
import part1_3 from 'assets/images/product/cdp/part1_3.png'
import part1_4 from 'assets/images/product/cdp/part1_4.png'
import part1_5 from 'assets/images/product/cdp/part1_5.png'
import part2_1 from 'assets/images/product/cdp/en/Data_integration.png'
import part2_2 from 'assets/images/product/cdp/en/Data_modeling.png'
import part2_3 from 'assets/images/product/cdp/en/Data_processing.png'
import part2_4 from 'assets/images/product/cdp/en/Data_Application.png'
import part2_5 from 'assets/images/product/cdp/en/Platform_Capabilities.png'
import part3_icon1 from 'assets/images/product/cdp/part3_icon1.svg'
import part3_icon2 from 'assets/images/product/cdp/part3_icon2.svg'
import part3_icon3 from 'assets/images/product/cdp/part3_icon3.svg'
import part3_icon4 from 'assets/images/product/cdp/part3_icon4.svg'
import part4_img from 'assets/images/product/cdp/part4_img.png'
import part4_icons from 'assets/images/product/cdp/en/01.png'
import part5_1 from 'assets/images/product/cdp/en/Entity_Query_Language.png'
import part5_2 from 'assets/images/product/cdp/en/Data_asset_view.png'
import part5_3 from 'assets/images/product/cdp/en/Tag_Group_Management.png'
import part5_4 from 'assets/images/product/cdp/en/Data_Service.png'

interface cdpProps {
  [x: string]: any
}

const defaultButtons = [
  {
    text: 'Free Demo',
    btnType: 'primary',
    href: '/api/client/user/router?demo=Retail',
    target: '_blank',
  },
]

const part2Data = [
  {
    title: 'Data Integration',
    sortTitle: 'Data Integration',
    content: {
      img: part2_1,
      introduction1: 'Comprehensive Data Collection and Access',
      introductionList: [
        {
          content:
            '· Seamless access to all your data sources, including user behavior data, business data, third-party data, data warehouses, data lakes, and more.',
        },
        {
          content:
            '· Visualized data access framework,  including 50+ SDKs, batch import tools, data warehouse table mapping, and more.',
        },
      ],
      buttons: defaultButtons,
    },
  },
  {
    title: 'Data Modeling',
    sortTitle: 'Data Modeling',
    reverse: true,
    content: {
      img: part2_2,
      introduction1: 'User-Friendly Data Modeling',
      introductionList: [
        { content: '· Real-time ID mapping for data association and unified user system across touch points.' },
        {
          content:
            '· Support to integrate user behavior data and business operation data, including order data, inventory details, and more.',
        },
        {
          content:
            '· Integration of user behavior data and business operation data, including order data, inventory details, and offline redemption data.',
        },
      ],
      buttons: defaultButtons,
    },
  },
  {
    title: 'Data Processing',
    sortTitle: 'Data Processing',
    reverse: true,
    content: {
      img: part2_3,
      introduction1: 'Flexible Segmentation Capabilities',
      introductionList: [
        {
          content:
            '· Business-oriented Entity Query Language (EQL) for simplified complex logic segmentation and quick queries.',
        },
        {
          content:
            '· Flexible rule engine supporting various complex rule definitions, such as multi-level and cross-comparison rule configurations.',
        },
        {
          content:
            '· User-friendly interface which catering to the needs of different users and empowering self-sufficiency.',
        },
      ],
      buttons: defaultButtons,
    },
  },
  {
    title: 'Data Application',
    sortTitle: 'Data Application',
    reverse: true,
    content: {
      img: part2_4,
      introduction1: 'Holistic Tagging System and Persona Insight',
      introductionList: [
        {
          content:
            '· Self-service creation and maintenance of user tags through a visual interface, tailored to business requirements.',
        },
        {
          content:
            '· Building a 360-degree customer profile for comprehensive and in-depth insights, guiding the next steps of business operations.',
        },
      ],
      buttons: defaultButtons,
    },
  },
  {
    title: 'Platform Capabilities',
    sortTitle: 'Platform Capabilities',
    reverse: true,
    content: {
      img: part2_5,
      introduction1: 'Open Platform Capabilities',
      introductionList: [
        {
          content:
            '· Support real-time data processing, sub-second end-to-end data streaming, and real-time data subscription and output.',
        },
        {
          content:
            '· Support both streaming and batch output, high-concurrency real-time queries, and  tens of thousands of QPS for various online marketing.',
        },
        {
          content:
            '· Seamlessly integrates with the full range of Sensors Data products and offers flexible capabilities expansion through OpenAPI.',
        },
      ],
      buttons: defaultButtons,
    },
  },
].map((item) => ({
  ...item,
  reverse: true,
  showIcon: false,
}))

const part3Data = [
  {
    icon: part3_icon1,
    title: 'Get a 360° View',
    contentText: 'Build a 360-degree profile for enhanced data insights.',
  },
  {
    icon: part3_icon2,
    title: 'Build Your Data Assets',
    contentText: 'Collect customer data in one plece to manage your data assets.',
  },
  {
    icon: part3_icon3,
    title: 'Provide Proactive Experiences',
    contentText: 'Facilitate user segmentation to create personalized experiences.',
  },
  {
    icon: part3_icon4,
    title: 'Adapt to Various Scenarios',
    contentText: 'Extract entity-based features to power intricate marketing strategies.',
  },
]

const part4Data = {
  imgUrl: part4_img,
  title: 'Connect Data to Boost Your GMV',
  text: [
    'The AFIONA Information Center team partnered with Sensors Data on a CDP project, tackling data challenges like silos, capability gaps, computing constraints for user outreach, and data flow difficulties. This collaboration significantly boosted new member GMV, new member count, new member ARPU, registered users, and first-order conversion rate.',
    '"The launch of the Sensors Data CDP project is a milestone for AFIONA, setting higher expectations for our team to leverage data for productivity."',
  ],
  signature: '—— AFIONA CEO',
}

const part5Data = [
  {
    title: 'Entity Query Language (EQL)',
    desc: 'Support a simpler and more efficient data processing method',
    img_url: part5_1,
  },
  {
    title: 'Data Asset Views',
    desc: 'Offer more comprehensive enterprise data assets insights',
    img_url: part5_2,
  },
  {
    title: 'Tag/Group Management',
    desc: 'Adapt to various business scenarios and support structured tag/group management',
    img_url: part5_3,
  },
  {
    title: 'Data Services',
    desc: 'Offer extensive OpenAPI, plug-and-play, and data export capabilities',
    img_url: part5_4,
  },
]

// const part6Data = [
//   {
//     img: part6_1,
//     title: '神策数据入选 IDC 中国客户数据平台 CDP …',
//     tip: '文章',
//     url: 'https://www.sensorsdata.cn/blog/20230414',
//     text: '立即阅读',
//   },
//   {
//     img: part6_2,
//     title: '新一代的营销数据平台',
//     tip: '文章',
//     url: 'https://www.sensorsdata.cn/blog/20221122',
//     text: '立即阅读',
//   },
//   {
//     img: part6_3,
//     title: '《CDP 全域用户关联数据体系建设...',
//     tip: '资料',
//     url: 'https://www.sensorsdata.cn/school/library/2789b755-1cfd-4eac-aaf4-1a6ebbf3455e',
//     text: '免费下载',
//   },
//   {
//     img: part6_4,
//     title: '《企业数据治理价值解读与场景实践》',
//     tip: '资料',
//     url: 'https://www.sensorsdata.cn/school/library/6e2c65db24a3f6cf408e969329c69210',
//     text: '免费下载',
//   },
// ]

const Buttons = () => (
  <div className={styles.buttons}>
    <Button btnType="primary" target="_blank" href="/api/client/user/router?demo=Retail">
      Free Demo
    </Button>
    <Button btnType="primary" target="_blank" href="/form/parade/" ghost>
      Contact us
    </Button>
    {/* <ScanCodeConsultButton
      qrcode="https://ow-file.sensorsdata.cn/www/product/analysis/wechatQRcode.png"
      buttonText="立即咨询"
      imgBottomText="微信扫码 立即咨询"
      btnType="primary"
      ghost
    /> */}
  </div>
)

const Cdp: React.FC<cdpProps> = (props) => {
  return (
    <Layout {...props}>
      <Seo
        title="Sensors Data | Sensors CDP"
        description="To empower enterprises in achieving comprehensive customer management, the Sensors Data platform leverages powerful data governance capabilities by integrating multi-source data, associating universal IDs, expanding data models, and constructing customer profile tags, helping businesses build customer data platforms to support scenarios such as business analysis insights and automated marketing."
        keywords="Sensors Data platform, customer management, CDP, customer data platform, data analysis, data governance"
      />
      <div className={styles.cdpRoot}>
        <div className={styles.bannerWrapper}>
          <Banner
            title="Sensors CDP"
            subTitle="Your Data Foundation for Customer Operation"
            desc="The Enterprise Customer Data Platform (CDP) focuses on eliminating data silos, unifying customer and product profiles, and empowering  companies to drive customer operations with data."
            h1tag={true}
            className={classNames(styles.banner)}
            bannerClassname={styles.bannerContent}
            bannerTitleClassName={styles.bannerTitle}
            bannerSubTitleClassName={styles.bannerSubTitle}
            bannerBtnsClass={styles.bannerBtns}
            bannerDescClassName={styles.bannerDesc}
            buttons={[
              {
                text: 'Free Demo',
                href: '/api/client/user/router?demo=Retail',
                btnType: 'primary',
              },
              // {
              //   useQrCode: true,
              //   qrcode: 'https://ow-file.sensorsdata.cn/www/product/analysis/wechatQRcode.png',
              //   buttonText: '立即咨询',
              //   imgBottomText: '微信扫码 立即咨询',
              //   btnType: 'primary',
              //   ghost: true,
              // },
            ]}
          />
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="Collect and Manage All Your Customer Data" />
          <div className={styles.partContent}>
            <CardGroup
              data={[
                {
                  title: 'Cross-channel Data Integration',
                  imageSrc: part1_1,
                  contentLines: ['· ID-Mapping for data linkage', '· Data access methods toolkits'],
                },
                {
                  title: 'Real-time Data Processing',
                  imageSrc: part1_2,
                  contentLines: ['· Sub-second data streaming', '· Real-time data subscription'],
                },
                {
                  title: 'Flexible User Segmentation',
                  imageSrc: part1_3,
                  contentLines: ['· Adaptable engine for complex rules', '· User-friendly interface for all users'],
                },
                {
                  title: 'Cost-effective Implementation',
                  imageSrc: part1_4,
                  contentLines: [
                    '· Directly mapping to the data warehouse, eliminating the need for ETL implementation resources',
                    '· Visual data access, modeling, and processing interface with no development dependencies',
                  ],
                },
                {
                  title: 'High-performance Querying',
                  imageSrc: part1_5,
                  contentLines: [
                    '· Daily processing for thousands of campaigns, serving over 20 billion individuals',
                    '· High-concurrency (ten thousand concurrent users on three nodes) and low-latency (millisecond-level) single entity tag profile query capabilities',
                  ],
                },
              ]}
            />
          </div>
          <Buttons />
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="A Real-time, Self-serve, Agile Customer Data Platform" />
          <div className="lg:w-[1200px] mt-[16px] mx-auto lg:mt-[60px] lg:mb-[80px]">
            <TabSwitcher data={part2Data} longTitle align="space-between" mbShowShadow={false} autoWAndH></TabSwitcher>
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="Drive Your Business Forward with Sensors CDP" />
          <div className={styles.partContent}>
            <CardsCol data={part3Data} />
          </div>
          <Buttons />
        </div>
        <div className={styles.bWarrper}>
          <div className="flex justify-center md:mt-[0px] pl-[20px] pr-[20px]">
            <TestimonyCard {...part4Data} />
          </div>
          <div className={classnames(' lg:w-[1070px] lg:px-0 mx-[auto] px-[20px] w-full', styles.carouselWrapper)}>
            <ScrollLogoWall
              className={classnames(' mt-[20px] ', styles.logo_wall_en)}
              logo_wall={part4_icons}
              title="Connect Data to Boost Your GMV"
            />
          </div>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="More Features to Power Your Data" />
          <div className="lg:w-[1200px] lg:mt-[60px] mt-[26px] mx-auto px-[2rem] lg:px-0 lg:flex lg:justify-between">
            {part5Data.map((item) => {
              return <AnalysisCard className="mt-[20px] lg:mt-0" {...item} key={item?.title} />
            })}
          </div>
          <Buttons />
        </div>
        {/* <div className={styles.bWarrper}>
          <SummaryTitle title="更多干货" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] pl-[20px] pr-[20px]">
            <MoreResources data={part6Data} />
          </div>
        </div> */}
        <div className="h-[100px] hidden md:block"></div>
        <div className="pt-[6rem] md:pt-[0px] bg-[#f9fafc]">
          <PageBottomCard
            title="Start the Journey of Big Data Analysis and Marketing Technology!"
            desc="Register now to talk digitalization with the experts at Sensors Data!"
            leftButtonText="Contact Sales"
            leftButtonHref="/form/parade/"
            // rightButtonText="预约演示"
            // rightButtonHref="/form/parade.html"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Cdp
